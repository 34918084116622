import './style.scss'
import Imac from '../../Assets/imac.png'
import { Bounce } from 'react-reveal'

export default function Fourth(){
    return(
        <div className="fourth-page">
            <Bounce bottom cascade>
            <div className='img'>
                <div>
                    <img src={Imac}/>
                </div>
            </div>
            <div className='text'>
                <div>
                    <h1 style={{color: '#0DAAC9', fontSize: '1rem', fontWeight: '400'}}>DIGITALIZA TU NEGOCIO</h1>
                    <h1>Incrementa tus ventas</h1>
                    <p>
                        Mejora la rentabilidad de tu negocio con tu presencia en internet.<br/>
                        Olvídate del problema de modificar o crear tu página, nosotros lo hacemos por ti.<br/>
                        Mejoramos tu servicio al cliente para que solo te preocupes por vender más con nuestro chatbot.‍
                    </p>
                </div>
            </div>
            </Bounce>
        </div>
    )
}