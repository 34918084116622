import { Button, Checkbox, Form, Input } from 'antd';
import './style.scss'

const { TextArea } = Input;
export default function Footer(){
    const [form] = Form.useForm();
    const sendMail = async(data, url) => {
        try {
            let response = await fetch(`${url}`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            let res = await response.json()
            return(res)
        }catch (error) {
            console.log(error)
        }
    }

    const onFinish = (values) => {
        alert('Datos enviados correctamente, pronto nos comunicaremos con usted')
        form.resetFields()
        sendMail(values, "https://apimuseoupn.com/api/mail")
    }
    return(
        <div className='footer' id="contact">
            <div className='title'>
                CONTACTANOS
            </div>
            <div className='form'>
                <Form
                    name="basic"
                    labelCol={{
                    span: 8,
                    }}
                    wrapperCol={{
                    span: 16,
                    }}
                    style={{
                    maxWidth: 600,
                    }}
                    onFinish={onFinish}
                    autoComplete="off"
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        label={<label style={{ color: "white" }}>E-Mail</label>}
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'No es un E-mail valido',
                            },
                            {
                                required: true,
                                message: 'Por favor ingrese su correo',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<label style={{ color: "white" }}>Mensaje</label>}
                        name="message"
                        rules={[
                            {
                            required: true,
                            message: 'Por favor ingrese un mensaje',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>

                    

                    <Form.Item>
                        <Button type="default" htmlType="submit" style={{width: '100%', background: 'rgba(255,255,255,0.7)'}}>
                            Enviar
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}