import './style.scss'
import Apps from '../../Assets/apps.jpg'
import Conocimiento from '../../Assets/conocimiento.png'
import Eficiente from '../../Assets/eficiente.jpg'
import Optimizar from '../../Assets/optimizar.jpg'
import { Bounce } from 'react-reveal'

export default function Sixth(){
    return(
        <div className="sixth-page">
            <Bounce bottom cascade>
            <div className='text'>
                <div>
                    <img alt='Conextra' src={Conocimiento} />
                    <h1>Experiencia y Conocimiento Especializado</h1>
                    <p>
                        Los servicios de consultoría cuentan con profesionales expertos y experiencia en el proceso de obtención de licencias de frecuencias ante el MINTIC.
                    </p>
                </div>
            </div>
            <div className='text'>
                <div>
                    <img alt='Conextra' src={Optimizar} />
                    <h1>Optimización de Recursos y Tiempo</h1>
                    <p>
                        Optimiza tus recursos y tiempo en la obtención de licencias de frecuencias. Somos expertos en los procedimientos del MINTIC. Deja el trabajo a los expertos.
                    </p>
                </div>
            </div>
            <div className='text'>
                <div>
                    <img alt='Conextra' src={Eficiente} />
                    <h1>Mejora de la Eficiencia Operativa y Reducción de Costos</h1>
                    <p>
                        La transformación digital lleva a una mejora significativa en la eficiencia operativa de tu empresa, optimiza flujos de trabajo y reduce la dependencia de sistemas obsoletos.
                    </p>
                </div>
            </div>
            </Bounce>
        </div>
    )
}