import './App.scss';
import Navbar from './Component/Navbar'
import Home from './Component/Home'
import Second from './Component/Second'
import Thrid from './Component/Thrid'
import Fourth from './Component/Fourth'
import Fifth from './Component/Fifth'
import Sixth from './Component/Sixth';
import Seventh from './Component/Seventh';
import Eighth from './Component/Eighth';
import Footer from './Component/Footer';

function App() {
  return (
    <div className="container">
      <div className='home'>
        <Navbar />
        <Home />
        <Second />
        <Thrid />
        <Fourth />
        <Fifth />
        <Sixth />
        <Seventh />
        <Eighth />
        <Footer />
      </div>
    </div>
  );
}

export default App;
