import './style.scss'
import Apps from '../../Assets/apps.jpg'
import { Bounce } from 'react-reveal'

export default function Fifth(){
    return(
        <div className="fifth-page" id="apps">
            <Bounce bottom cascade>
            <div className='text'>
                <div>
                    <h1 style={{color: '#0DAAC9', fontSize: '1rem', fontWeight: '400'}}>DIGITALIZA TU NEGOCIO</h1>
                    <h1>Creación de APP's</h1>
                    <p>
                        Cambia los viejos modelos de negocio y empieza a medir tus resultados.<br/>
                        Ten el control de tus clientes y mide su nivel de satisfacción.<br/>
                        Te hacemos la vida más fácil a ti y a tus clientes.
                    </p>
                </div>
            </div>
            <div className='img'>
                <div>
                    <img src={Apps}/>
                </div>
            </div>
            </Bounce>
        </div>
    )
}