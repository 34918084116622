import './style.scss'
import Chatbot from '../../Assets/chatbot.jpg'
import { Bounce } from 'react-reveal'

export default function Seventh(){
    return(
        <div className="seventh-page">
            <Bounce bottom cascade>
            <div className='text'>
                <div>
                    <h1 style={{color: '#0DAAC9', fontSize: '1rem', fontWeight: '400'}}>SERVICIO AL CLIENTE</h1>
                    <h1>ChatBot</h1>
                    <p>
                        Mejora el tiempo de respuesta, atiende a tus clientes de forma inmediata para generar un compromiso, una retroalimentación o para dar seguimiento a sus solicitudes.<br/>
                        Un excelente servicio al cliente se traduce en mayores ganancias.
                    </p>
                </div>
            </div>
            <div className='img'>
                <div>
                    <img src={Chatbot}/>
                </div>
            </div>
            </Bounce>
        </div>
    )
}