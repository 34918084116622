import './style.scss'
import Rentabilidad from '../../Assets/rentabilidad.png'
import { Bounce } from 'react-reveal'

export default function Thrid(){
    return(
        <div className="thrid-page" id="transform">
            <Bounce bottom cascade>
            <div className='text'>
                <div>
                    <h1>Aumenta tu rentabilidad</h1>
                    <p>
                        - Te ayudamos con todos los pasos del proceso hasta que obtienes tu frecuencia.<br/>
                        - Mejora tu rentabilidad y obtén tu licencia para que licites con el Estado.<br/>
                        - Ahorra millones y prepárate para las auditorías del MINTIC.<br/>
                        - Opera con la mejor seguridad y mejor eficiencia en la frecuencia correcta.
                    </p>
                </div>
            </div>
            <div className='img'>
                <div>
                    <img src={Rentabilidad}/>
                </div>
            </div>
            </Bounce>
        </div>
    )
}