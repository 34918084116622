import './style.scss'
import Trabajo from '../../Assets/trabajo.jpg'
import { Bounce } from 'react-reveal'

export default function Eighth(){
    return(
        <div className="eighth-page">
            <Bounce bottom cascade>
            <div className='img'>
                <div>
                    <img src={Trabajo}/>
                </div>
            </div>
            <div className='text'>
                <div>
                    <h1 style={{color: '#0DAAC9', fontSize: '1rem', fontWeight: '400'}}>EXPERTOS A TU SERVICIO</h1>
                    <h1>Trabajamos por ti</h1>
                    <p>
                        Nos preparamos día a día, expandiendo nuestro conocimiento y acumulando experiencia, para que tú solo te enfoques en hacer crecer tu negocio mientras nosotros nos ocupamos de proporcionarte soluciones confiables y efectivas.
                    </p>
                </div>
            </div>
            </Bounce>
        </div>
    )
}